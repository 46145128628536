/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Link, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import CampHoc from '../../components/CampHoc';

// eslint-disable-next-line react/prop-types
const CrossCountryNotTent = ({ data }) => {
  const seo = {
    url: 'https://www.discoverlapland.fi/hiihtovaellukset/haltin-hiihtovaellus',
    image: `https://www.discoverlapland.fi${data.imageSEO.childImageSharp.gatsbyImageData.images.fallback.src}`,
    title: 'Haltin Hiihtovaellus',
    description:
      'Upeiden keväthankien aikaan Suomen korkeimpien tuntureiden juurilla, hiihtäen hiljaa Suomen huipulle. Haltin hiihtovaelluksen ajankohta on 4.-11.4.2025 ja mukaan mahtuu seitsemän talviretkeilykokemusta omaavaa naista.',
  };

  return (
    <CampHoc
      heroImage={data.campBg.childImageSharp.gatsbyImageData}
      seo={seo}
      camp="haltin-hiihtovaellus"
    >
      <div className="camp-text-content">
        <h2>HALTIN HIIHTOVAELLUS</h2>
        <h3>4.-11.4.2025</h3>
        <p className="bolded">
          Upeiden keväthankien aikaan Suomen korkeimpien tuntureiden juurilla,
          hiihtäen hiljaa Suomen huipulle. Haltin hiihtovaelluksen ajankohta on
          4.-11.4.2025 ja mukaan mahtuu seitsemän talviretkeilykokemusta omaavaa
          naista.
        </p>
        <br />
        <p>
          Kuusi päivää ja viisi yötä Suomen upeimmalla hiihtovaellusreitillä
          Norjan rajan tuntumassa. Tällä retkellä vedetään omaa ahkiota ja
          yövytään matkalla varaustuvissa. Teltat ovat kuitenkin mukana ja
          telttayöpymiseen tulee varautua, sillä sää Käsivarressa on hyvin
          arvaamaton.
        </p>
        <br />
        <p>
          Haltin hiihtovaellus sopii naisille, joilla on jo talviretkeily-
          /hiihtovaelluskokemusta. Päivämatka ovat 10-20 km välillä, koko reitin
          pituudeksi tulee noin 115km. Säiden salliessa hiihtovaelluksella on
          tavoitteena päästä huiputtamaan Suomen korkein kohta Haltilla. Jos sää
          ei ole tähän suosiollinen, huiputusta ei turvallisuussyistä tehdä.
        </p>
      </div>
      <div className="offering-img-row">
        <GatsbyImage
          image={data.campImg1.childImageSharp.gatsbyImageData}
          className="offering-img-vert"
          alt="image"
        />
        <GatsbyImage
          image={data.campImg2.childImageSharp.gatsbyImageData}
          className="offering-img-vert"
          alt="image"
        />
      </div>
      <div className="camp-text-content">
        <p className="bolded">Mitä hiihtovaellus tarjoaa?</p>
        <p>
          Hiihtovaellus Käsivarren erämaassa tarjoaa upean mahdollisuuden kokea
          Lapin upea luonto ja nauttia ainutlaatuisesta talvisesta maisemasta.
          Käsivarsi on tunnettu upeista tunturimaisemistaan ja koskemattomasta
          luonnostaan. Huhtikuussa päivät alkavat olla jo huomattavasti
          pidempiä, ja voit nauttia kauniista kevättalven valosta.
          Auringonpaiste ja vaalea kevätaurinko luovat hienoja valoefektejä
          maisemissa. Rauhallisuus ja luonnon hiljaisuus tekevät
          hiihtovaelluksesta ainutlaatuisen ja rentouttavan. Lähtemällä mukaan
          tähän ainutlaatuiseen talviseikkailuun, saat uutta oppia
          talviretkeilystä ja hiihtovaelluksesta samalla, kun tapaat
          samanhenkisiä, mahtavia naisia. Anna itsesi inspiroitua
          luonnonvoimista sekä upeista naisista ympärilläsi.
        </p>
      </div>
      <br />
      <div className="camp-text-content">
        <p className="bolded">Hiihtovaelluksen opas</p>
        <p>
          Oppaana hiihtovaelluksella toimii eräopas Sanni Lampinen. Muoniossa
          asuva Sanni on toiselta ammatiltaan fysioterapeutti ja hän toimii
          oppaana Discover Laplandin hiihtovaellusten lisäksi myös kesän
          vaelluksilla. Sanni on kiinnostunut ihmisen kokonaisvaltaisesta
          hyvinvoinnista ja siitä, miten voimme luonnossa eheyttää itseämme.
          Retkeilijänä Sanni on nautiskelija ja seikkailija. Hän nauttii
          ympäröivästä luonnosta, sen kauniista yksityiskohdista, rauhasta, eri
          sääolosuhteista ja kaikesta erilaisuudesta mitä luonto meille tarjoaa.
        </p>
      </div>
      <GatsbyImage
        image={data.campImg3.childImageSharp.gatsbyImageData}
        className="offering-img"
        alt="image"
      />
      <div className="camp-text-content">
        <p className="bolded">Aikataulu:</p>
        <p>
          Perjantaina 4.4.2025 tapaaminen Kilpisjärvellä, jossa yöpyminen
          yhteismökkimajoituksessa. Lauantaiaamuna alkaa hiihtovaellus kohti
          Haltia. Matkalla yövytään viisi yötä varaustuvissa ja hiihtovaellus
          päättyy takaisin Kilpisjärvelle. Mökissä ollaan vielä viimeinen
          10.-11.4.2025 välinen yö.
        </p>
        <br />
        <p className="bolded">
          Haltin hiihtovaelluksen hinta 1290€ sis. alv <br />
          Hintaan kuuluu:
        </p>
        <p>
          - Opas koko retken ajaksi <br />
          - Retkisuunnitelma sekä turvallisuussuunnitelma <br />
          - Pakkauslista retkelle <br />
          - Ahkio, tunturisukset, monot, sauvat <br />
          - Viisi yötä matkalla varaustuvissa <br />
          - Teltta hätämajoitusta varten <br />
          - Kaksi yötä yhteismökkimajoituksessa Kilpisjärvellä <br />-
          Verkkotapaamiset ennen retkeä, jossa käymme hiihtovaellukseen
          liittyvät asiat läpi. Ensimmäinen online-tapaaminen 21.2.2025 klo
          19-21 ja toinen 28.3.2025 klo 19-21.
        </p>
        <br />
        <p className="bolded">Sinulla tulee itselläsi olla:</p>
        <p>
          - Hiihtovaellukseen sopivat vaatteet <br />
          - Talvikäyttöön sopiva makuupussi ja makuualustat <br />
          - Retkiruuat <br />
          - Ruokailuvälineet <br />- Kuivasäkit, jotka kulkevat ahkiossa ja
          joissa säilytät tavarasi <br />
          <br />
          Retkelle otetaan max 7 naista, vähimmäismäärä on 5hlö, jotta retki
          toteutuu. Sinun tulee varata retkeen yksi ylimääräinen yö, jos
          joudumme yöpymään tunturissa yhden yön ylimääräistä säistä johtuen.
        </p>
      </div>
      <GatsbyImage
        image={data.campImg4.childImageSharp.gatsbyImageData}
        className="offering-img"
        alt="image"
      />
      <div className="camp-text-content">
        <p className="bolded">
          Ilmoittaudu mukaan sähköpostitse{' '}
          <a className="bolded" href="mailto: anettelatvapiikkila@gmail.com">
            anettelatvapiikkila@gmail.com
          </a>{' '}
          ja toimi näin:
        </p>
        <p>
          Otsikko: Haltin hiihtovaellus <br />
          <br />
          Vastaa meilissä ao. kysymyksiin: <br />
          Koko nimi: <br />
          Ikä: <br />
          Puhelinnumero: <br />
          Paikkakunta: <br />
          Mikä on aikaisempi talviretkeilykokemuksesi? <br />
          Mikä on aikaisempi kokemus hiihtämisestä? <br />
          Mikä on kuntotasosi eli kuinka paljon ja miten liikut normaalisti
          arjessa? <br />
          Mikä on terveydentilasi (onko polvi/nivelongelmia, astmaa jne.)?{' '}
          <br />
          <br />
          Sinulle tulee kustannettavaksi omat kuljetukset Kilpisjärvelle ja
          takaisin kotiin. Pidäthän huolen, että sinulla on voimassa oleva
          vapaa-ajan- tai matkavakuutus hiihtovaelluksen aikana.
        </p>
        <br />
        <p className="bolded">Retken varaus- ja peruutusehdot:</p>
        <p>
          Kun olet ilmoittautunut mukaan sähköpostitse, saat paluupostina
          materiaalikululaskun 290€. Kun lasku on maksettu, on paikka
          hiihtovaellukselle varattu ja saat paluupostina hiihtovaelluksen
          materiaalit; pakkauslistan ja retkisuunnitelman sekä
          online-tapaamisten linkit.
          <br />
          <br />
          Hiihtovaelluksen loppulaskun 1000€ saat sähköpostiisi hiihtovaelluksen
          aloituspäivänä, jolloin lasku tulee maksaa ja lähettää tästä
          kuittikopio osoitteeseen{' '}
          <a className="bolded" href="mailto: anettelatvapiikkila@gmail.com">
            anettelatvapiikkila@gmail.com
          </a>
          .
          <br />
          <br />
          Jos perut osallistumisesi hiihtovaellukselle missä kohtaa tahansa,
          pidätämme toimitetut hiihtovaelluksen materiaalien kulut sekä
          toimistokulut 290€. Ilmoittautumisen peruutus tulee tehdä
          sähköpostitse osoitteeseen{' '}
          <a className="bolded" href="mailto: anettelatvapiikkila@gmail.com">
            anettelatvapiikkila@gmail.com
          </a>
          .
          <br />
          <br />
          Tervetuloa lumoavaan matkaan, joka täyttää sielusi ja sydämesi
          seikkailunhaluisella ilolla!
          <br />
          <br />
          Tekisikö mieli mukaan tälle upealle hiihtovaellukselle? Mikäli sinulla
          on kysyttävää, voit olla yhteydessä Anetteen joko meilitse{' '}
          <a className="bolded" href="mailto: anettelatvapiikkila@gmail.com">
            anettelatvapiikkila@gmail.com
          </a>
          tai soittamalla 040 772 4124.
        </p>
      </div>
    </CampHoc>
  );
};

export const query = graphql`
  {
    campBg: file(
      relativePath: { eq: "images/crosscountry/halti-hiihtovaellus-2023-1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    campImg1: file(
      relativePath: { eq: "images/crosscountry/Ahkio-Halti.jpeg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    campImg2: file(
      relativePath: { eq: "images/crosscountry/Sanni-ahkio.jpeg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    campImg3: file(
      relativePath: { eq: "images/crosscountry/halti-hiihtovaellus-2023-2.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    campImg4: file(
      relativePath: { eq: "images/crosscountry/halti-hiihtovaellus-2023-3.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    imageSEO: file(
      relativePath: { eq: "images/crosscountry/halti-hiihtovaellus-2023-1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1366, layout: FIXED)
      }
    }
  }
`;

export default CrossCountryNotTent;
